// Typography
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;600&display=swap');

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// App Stylesheets
@import './styles/custom.scss';
@import './styles/responsive.scss';
